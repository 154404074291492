body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Faden bei Teams */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.8s ease-out;
}

/* Sicherstellen, dass die Navbar immer oben ist */
body {
  margin: 0;
  padding: 0;
  position: relative;
}

/* Sicherstellen, dass die Karte oder andere Komponenten nicht die Navbar überdecken */
#map-container {
  z-index: 1;
}

nav {
  z-index: 1000; /* Höherer Wert als andere Komponenten */
}



/* Smooth Scrollen bei Navbar */
html {
  scroll-behavior: smooth;
}




@tailwind base;
@tailwind components;
@tailwind utilities;


/* Modal Stile */
.modal {
  width: 80%;
  max-width: 800px;
  margin: auto;
  background: white;
  padding: 20px;
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* Optional: Schließen-Button-Stil */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}